document.addEventListener("DOMContentLoaded", async () => {
  const token = localStorage.getItem("token");
  console.log("User validation");
  if (token === null || (typeof token === "string" && token.length === 0)) {
    localStorage.removeItem("token");
    logout();
    window.location.href = "/login";
  } else {
    const tokenValidation = await verifyToken(token);
    if (tokenValidation == 200) {
      document.body.style.display = "block";
    } else {
      localStorage.removeItem("token");
      logout();
      window.location.href = "/login";
    }
  }
});

async function verifyToken(token) {
  try {
    const response = await fetch(`http://localhost:3001/crawler/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.status;
  } catch (error) {
    throw new Error(`Network response was not ok ${error.message}`);
  }
}

// logout
function logout() {

  console.log("Call logout");
  const token = localStorage.getItem("token");
  if (!token) {
    console.error(
      "token not found on local storage",
    );
    return;
  }

  fetch("http://localhost:3001/user/logout", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      // eslint-disable-next-line no-console
      console.log("logout", response);
      if (!response.ok) {
        throw new Error("Error on logout request");
      }

      localStorage.removeItem("token");
      window.location.href = "/login";
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Error en logout:", error);
    });
}
/* global Chart, coreui */

/**
 * --------------------------------------------------------------------------
 * CoreUI Boostrap Admin Template main.js
 * Licensed under MIT (https://github.com/coreui/coreui-free-bootstrap-admin-template/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

// Disable the on-canvas tooltip
Chart.defaults.pointHitDetectionRadius = 1;
Chart.defaults.plugins.tooltip.enabled = false;
Chart.defaults.plugins.tooltip.mode = "index";
Chart.defaults.plugins.tooltip.position = "nearest";
Chart.defaults.plugins.tooltip.external = coreui.ChartJS.customTooltips;
Chart.defaults.defaultFontColor = coreui.Utils.getStyle("--cui-body-color");

document.documentElement.addEventListener("ColorSchemeChange", () => {
  cardChart1.data.datasets[0].pointBackgroundColor =
    coreui.Utils.getStyle("--cui-primary");
  cardChart2.data.datasets[0].pointBackgroundColor =
    coreui.Utils.getStyle("--cui-info");
  mainChart.options.scales.x.grid.color = coreui.Utils.getStyle(
    "--cui-border-color-translucent",
  );
  mainChart.options.scales.x.ticks.color =
    coreui.Utils.getStyle("--cui-body-color");
  mainChart.options.scales.y.border.color = coreui.Utils.getStyle(
    "--cui-border-color-translucent",
  );
  mainChart.options.scales.y.grid.color = coreui.Utils.getStyle(
    "--cui-border-color-translucent",
  );
  mainChart.options.scales.y.ticks.color =
    coreui.Utils.getStyle("--cui-body-color");

  cardChart1.update();
  cardChart2.update();
  mainChart.update();
});

const random = (min, max) =>
  // eslint-disable-next-line no-mixed-operators
  Math.floor(Math.random() * (max - min + 1) + min);

const cardChart1 = new Chart(document.getElementById("card-chart1"), {
  type: "line",
  data: {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "transparent",
        borderColor: "rgba(255,255,255,.55)",
        pointBackgroundColor: coreui.Utils.getStyle("--cui-primary"),
        data: [65, 59, 84, 84, 51, 55, 40],
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        min: 30,
        max: 89,
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        borderWidth: 1,
        tension: 0.4,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
  },
});

const cardChart2 = new Chart(document.getElementById("card-chart2"), {
  type: "line",
  data: {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "transparent",
        borderColor: "rgba(255,255,255,.55)",
        pointBackgroundColor: coreui.Utils.getStyle("--cui-info"),
        data: [1, 18, 9, 17, 34, 22, 11],
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        min: -9,
        max: 39,
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        borderWidth: 1,
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
  },
});

// eslint-disable-next-line no-unused-vars
const cardChart3 = new Chart(document.getElementById("card-chart3"), {
  type: "line",
  data: {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(255,255,255,.2)",
        borderColor: "rgba(255,255,255,.55)",
        data: [78, 81, 80, 45, 34, 12, 40],
        fill: true,
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        tension: 0.4,
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
  },
});

// eslint-disable-next-line no-unused-vars
const cardChart4 = new Chart(document.getElementById("card-chart4"), {
  type: "bar",
  data: {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
      "January",
      "February",
      "March",
      "April",
    ],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(255,255,255,.2)",
        borderColor: "rgba(255,255,255,.55)",
        data: [78, 81, 80, 45, 34, 12, 40, 85, 65, 23, 12, 98, 34, 84, 67, 82],
        barPercentage: 0.6,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  },
});

const mainChart = new Chart(document.getElementById("main-chart"), {
  type: "line",
  data: {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: `rgba(${coreui.Utils.getStyle("--cui-info-rgb")}, .1)`,
        borderColor: coreui.Utils.getStyle("--cui-info"),
        pointHoverBackgroundColor: "#fff",
        borderWidth: 2,
        data: [
          random(50, 200),
          random(50, 200),
          random(50, 200),
          random(50, 200),
          random(50, 200),
        ],
        fill: true,
      },
      {
        label: "My Second dataset",
        borderColor: coreui.Utils.getStyle("--cui-success"),
        pointHoverBackgroundColor: "#fff",
        borderWidth: 2,
        data: [
          random(50, 200),
          random(50, 200),
          random(50, 200),
          random(50, 200),
          random(50, 200),
        ],
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: 95,
            yMax: 95,
            borderColor: coreui.Utils.getStyle("--cui-danger"),
            borderWidth: 1,
            borderDash: [8, 5],
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: coreui.Utils.getStyle("--cui-border-color-translucent"),
          drawOnChartArea: false,
        },
        ticks: {
          color: coreui.Utils.getStyle("--cui-body-color"),
        },
      },
      y: {
        border: {
          color: coreui.Utils.getStyle("--cui-border-color-translucent"),
        },
        grid: {
          color: coreui.Utils.getStyle("--cui-border-color-translucent"),
        },
        ticks: {
          beginAtZero: true,
          color: coreui.Utils.getStyle("--cui-body-color"),
          max: 250,
          maxTicksLimit: 5,
          stepSize: Math.ceil(250 / 5),
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      },
    },
  },
});
